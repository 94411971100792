import React, { useMemo } from 'react';
import { Field, PanelProps } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { TagValue } from 'components/TagValue';

interface Props extends PanelProps<MetaOptions> {}

export const Meta: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  const { separator, tags, titles, columns } = options;
  const styles = useStyles2(getStyles(columns, width, height));
  const tagList = useMemo(() => tags?.split(separator).map((e) => e.trim()), [tags, separator]);
  const titleList = useMemo(() => titles?.split(separator).map((e) => e.trim()), [titles, separator]);
  return (
    <>
      {data.series
        .filter((s) => s.fields.filter((f) => f.values.length > 1).length === 0)
        .map((e) => (
          <div className={styles.wrapper} key={e.name}>
            {e.fields
              .filter((e) => (tagList?.length ? tagList.includes(e.name) : true))
              .sort((a, b) =>
                tagList?.length ? tagList.findIndex((e) => e === a.name) - tagList.findIndex((e) => e === b.name) : 0
              )
              .map((f: Field, idx) => (
                <div key={f.name}>
                  <TagValue
                    name={replaceVariables(titleList?.[idx] || f.name)}
                    value={f.display ? f.display(f?.values?.get(0))?.text : '---'}
                  />
                </div>
              ))}
          </div>
        ))}
    </>
  );
};

const getStyles = (columns: number, width: number, height: number) => () => {
  return {
    wrapper: css`
      display: grid;
      max-height: ${height};
      max-width: ${width};
      gap: 1em;
      grid-template-columns: repeat(${columns}, 1fr);
    `,
  };
};
