import React from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { css, cx } from '@emotion/css';

interface Props {
  name: string;
  value: string;
}

export const TagValue: React.FC<Props> = ({ name, value }) => {
  const styles = useStyles2(getStyles());
  return (
    <>
      <div className={cx(styles.base, styles.tag)}>{name}</div>
      <div className={cx(styles.base, styles.value)}>{value || '---'}</div>
    </>
  );
};
const getStyles = () => (theme: GrafanaTheme2) => {
  return {
    base: css`
      border-radius: 4px;
      margin: 0 0 0.25em;
      overflow: hidden;
    `,
    tag: css`
      font-size: small;
      font-weight: ${theme.typography.fontWeightLight};
    `,
    value: css`
      background: ${theme.colors.background.secondary};
      font-size: large;
      padding: 0.25em 0.5em;
      font-family: ${theme.typography.fontFamilyMonospace};
      font-weight: ${theme.typography.fontWeightBold};
    `,
  };
};
