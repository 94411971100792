import { PanelPlugin } from '@grafana/data';
import { Meta } from 'Meta';

export const plugin = new PanelPlugin<MetaOptions>(Meta).setPanelOptions((builder) => {
  const separator = builder.getRegistry().getIfExists('separator');
  return builder
    .addTextInput({
      name: 'Tag list',
      description: `Which tags should be included.${separator ? 'Separated by ' + separator : ''}`,
      path: 'tags',
    })
    .addTextInput({
      name: 'Tag titles',
      description: `Title for tags.${separator ? 'Separated by ' + separator : ''}`,
      path: 'titles',
    })
    .addNumberInput({
      name: 'columns',
      description: 'Number of columns to use',
      path: 'columns',
      defaultValue: 3,
    })
    .addTextInput({
      name: 'Separator',
      description: 'Separator for tags and titles',
      path: 'separator',
      defaultValue: ',',
    });
})
.useFieldConfig();
